.section__Item {
  margin: 20px;
  font-size: 14px;
}
.button__r__f {
  background-color: #002d62;
  padding: 8px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin: 3px;
}
.select_product_type {
  display: flex;

  gap: 10px;
  margin-left: 10px;
  align-items: center;
}
.select_item_name {
  display: flex;

  gap: 10px;
  align-items: center;
}
.item_code {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 70px;
}
.item_name {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-right: 30px;
}
.category {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 75px;
}
.sub-category {
  display: flex;

  gap: 10px;
  align-items: center;
}
.classification {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 50px;
}
.select_product_type {
  display: flex;

  gap: 10px;
  align-items: center;
}
.select_size {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 65px;
}
.description {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 30px;
}
.standard_cost {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-right: 130px;
}
.item_code_table {
  display: flex;

  justify-content: space-between;
}
.item_second_line {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}
.item_third_line {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}
.item_fourth_line {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}
.item_fifth_line {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}
.section__div {
  padding: 10px;
  border-radius: 5px;
  background-color: aliceblue;
}

.item__code__select2 {
  width: 400px;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
  margin: 3px;
  margin-right: 50px;
}
.item__code__select3 {
  width: 200px;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
  margin: 3px;
}
.item__code__input1 {
  width: 200px;
  border: 1px solid;

  height: 30px;
  margin: 3px;
}
.item__code__input2 {
  width: 300px;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
  margin: 3px;
}
.stock__list {
  color: red;
  padding: 0px 10px;
}
.text__area__item {
  margin-top: 10px;
  max-height: 80px;
  max-width: 800px;
  height: 80px;
  width: 800px;
  padding: 10px;
}
.select__margin__master {
  margin: 8px 0px;
  margin-right: 50px;
}
.section__div input {
  margin: 5px 0px;
}

/* Route Master */
.item_code_route {
  display: flex;
  gap: 10px;
  align-items: center;
}

.category_route {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header_route {
  display: flex;
  justify-content: flex-end;
  margin-top: 1px;
}
.header__save {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}
.section__for__print__excel {
  display: flex;
  justify-content: space-between;
}
.width__website__lower {
  width: 400px;
}
.search__mill {
  display: flex;
  font-size: 16px;
  margin: 0px 10px;
  gap: 10px;
}
.search__mill input {
  height: 30px;
  width: 300px;
  margin-left: 30px;
}
@media (max-width: 600px) {
  .item_code_table {
    display: block;
  }
  .item_second_line {
    display: block;
  }
  .item_third_line {
    display: block;
  }
  .item_fourth_line {
    display: block;
  }
  .item_fifth_line {
    display: block;
  }
  .select_product_type {
    display: block;

    margin-left: 20px;
  }
  .select_item_name {
    display: block;

    margin-left: 20px;
  }
  .item_code {
    display: block;

    margin-left: 20px;
  }
  .item_name {
    display: block;

    margin-left: 20px;
  }
  .category {
    display: block;

    margin-left: 20px;
  }
  .sub-category {
    display: block;

    margin-left: 20px;
  }
  .classification {
    display: block;

    margin-left: 20px;
  }
  .select_product_type {
    display: block;

    margin-left: 20px;
  }
  .select_size {
    display: block;

    margin-left: 20px;
  }
  .standard_cost {
    display: block;

    margin-left: 20px;
  }
  .description {
    display: block;

    margin-left: 20px;
  }
  .text__area__item {
    width: 300px;
  }
}

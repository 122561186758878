.header_import_import {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Printing Report  */

.table-print {
  width: 100%;
  /* border-collapse: collapse; */
}

.table-print thead tr {
  text-align: left;
  color: #8b0000; /* This is a dark red color, you can adjust as needed */
}

.table-print tbody tr {
  /* border: 1px solid #000; */
  padding: 2px 3px;
}

.table-print td,
.table-print th {
  padding: 2px 3px;
  /* border: 1px solid #000; */
}

.toSHow__when__Print {
  display: block;
}
.toshow__Print {
  display: none;
}
.toSHow__when__Print {
  display: none;
}

.print__body tr:nth-child(even) {
  background-color: #a8bee5;
}

@media print {
  .toHIde__when__Print {
    display: none;
  }
  .toSHow__when__Print {
    display: block;
  }
}

.import {
  color: brown;
  margin-left: 20px;
  font-size: 20px;
}

.supplier_name_import {
  display: flex;
  gap: 24px;
  font-size: 14px;
  align-items: center;
  width: 662px;
}

.Save_import {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 16px;
  margin-right: 10px;
  height: 30px;
  border-radius: 5px;
  /* margin: 20px 0px; */
  cursor: pointer;
  width: 70px;
  padding: 5px;
}
.view__detail__button {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 16px;
  margin-right: 10px;
  height: 30px;
  border-radius: 5px;
  /* margin: 20px 0px; */
  cursor: pointer;
  width: 150px;
  padding: 5px;
}
.Import_first_line {
  background-color: aliceblue;
  height: 260px;
  margin-top: 5px;
  font-size: 14px;
}
.import_second_line {
  display: flex;
  margin-top: 25px;
  font-size: 14px;
}
.cost_sheet_no_1 {
  display: flex;
  gap: 15px;
  margin-left: 10px;

  font-size: 14px;
  align-items: center;
  width: 270px;
}
.item_1 {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  width: 540px;
}
.qty_import {
  display: flex;
  gap: 10px;
  margin-right: 5px;
  font-size: 14px;
  align-items: center;
}
.import_third_line {
  display: flex;
  margin-top: 15px;
  font-size: 14px;
}
.import_fourth_line {
  display: flex;
  margin-top: 15px;
  font-size: 14px;
}
.import_fifth_line {
  display: flex;

  font-size: 14px;
  justify-content: space-between;
}

.vat {
  display: flex;
  gap: 3px;

  font-size: 14px;
  align-items: center;
}
.amt {
  display: flex;
  gap: 3px;

  font-size: 14px;
  align-items: center;
  width: 250px;
}
.costmt {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  width: 185px;
}
.cost_item {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  margin-left: 40px;
  width: 420px;
}

.supplier_name {
  display: flex;
  gap: 5px;
  font-size: 14px;
  align-items: center;
  margin-left: 1px;
  width: 450px;
}

.select_name_1 {
  width: 300px;
}
.vessel_name {
  display: flex;
  gap: 10px;
  font-size: 14px;
  align-items: center;
  margin-left: 4px;
  width: 450px;
}
.exc_rate {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  width: 490px;
}
.amt_ksh {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
}
.narr {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  margin-left: 43px;
}
.import_btn {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  margin: 0px 10px;
}
.checkbos__import__Costing {
  height: 20px;
  width: 20px;
  margin: 5px;
}
.text {
  max-width: 1000px;
  max-height: 100px;
  width: 900px;
  height: 50px;
  margin-top: 10px;
}
.other_charges1 {
  width: 50%;
  background-color: aliceblue;
  height: 500px;
}
.cost_exc {
  width: 50%;
  background-color: aliceblue;
  height: 500px;
}
.cost_vat {
  display: flex;
  color: brown;
  gap: 200px;
  margin-left: 70px;
}
.total_cost_exc {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  margin-left: 30px;
  font-size: 14px;
  align-items: center;
}
.total_cost_input {
  height: 30px;
  width: 200px;
}
.mt {
  margin-left: 80px;
}
.total_cost_exc_1 {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  margin-left: 18px;
  font-size: 14px;
  align-items: center;
}
.total_cost_exc_2 {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  margin-left: 16px;
  font-size: 14px;
  align-items: center;
}
.total_cost_exc_3 {
  margin-top: 10px;
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
}
.derived {
  color: brown;
  justify-content: center;
  display: flex;
  font-size: 24px;
  margin-top: 20px;
}
.other-charge {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.pro_select_1 {
  width: 330px;
}
.cost_input {
  height: 30px;
  width: 150px;
  margin-top: 5px;
}
.other_1 {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}
.cost_2 {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  justify-content: end;
  margin-right: 50px;
  margin-top: 20px;
}
.input_cost_2 {
  height: 30px;
  width: 150px;
}

.input_cost_costmt {
  height: 30px;
  width: 100px;
}

.sec__iport__changes {
  display: flex;
}
.input_size_bag5 {
  height: 30px;
  width: 100px;
}
.input_size_bag6 {
  height: 30px;
  width: 100px;
}
.hist_prod1 {
  display: flex;
  align-items: center;
  margin-top: 2px;

  gap: 5px;
}
.chart__active__buttons {
  display: flex;
  justify-content: end;
  margin: 0px 5px;
}
.chart__active__buttons > button {
  width: 40px;
  background-color: rgba(88, 86, 86, 0.626);
  border: none;
  margin: 5px;
  font-size: larger;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  display: flex;
  justify-content: center;
}

.chart__active__buttons > .active__chart {
  background: rgba(253, 253, 254, 0.676);
  border: 2px solid grey;

  color: rgb(10, 0, 0);

  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  transition: 0.1s ease-in-out;
}
.flex__pai {
  display: flex;
  justify-content: space-around;
}
.flex__pai > div {
  width: 400px;
}
.for__single__pai {
  width: 50%;
  height: 300px;
}
.select__cunsuption,
.route_select {
  width: 350px;
}
.header__of__page {
  font-weight: bold;
  font-size: 24px;
}
.date__custom {
  border: 1px solid;
  position: absolute;
  top: 180px;
  left: 20px;
  display: block;
  background: #f2efef;
  gap: 10px;
  z-index: 99;
  padding: 10px;
  border-radius: 5px;
  font-size: small;
}
.for__table__length {
  width: 60%;
}

@media (max-width: 600px) {
  .for__table__length {
    width: 100%;
  }
  .flex__pai {
    display: block;
  }
  .flex__pai > div {
    width: 100%;
  }
  .for__single__pai {
    width: 100%;
    height: 300px;
  }
  .import_second_line {
    display: block;
  }
  .hist_prod1 {
    display: block;
    margin-left: 10px;
    margin-top: 20px;
  }
  .input_size_bag5 {
    height: 30px;
    width: 150px;
    margin-top: 12px;
    margin-left: 1px;
  }
  .input_size_bag6 {
    height: 30px;
    width: 150px;
    margin-top: 13px;
    margin-left: 1px;
  }
  .cost_sheet_no_1 {
    display: block;
    margin-left: 20px;
    width: 250px;
    font-size: 16px;
  }
  .item_1 {
    display: block;
    margin: 10px;
    width: 300px;
    margin-left: 20px;
    font-size: 16px;
  }
  .Import_first_line {
    height: auto;
  }
  .qty_import {
    display: block;
    margin-top: 20px;
    margin-left: 20px;
    font-size: 16px;
  }

  .cost_item {
    display: block;
    margin-left: 20px;
    width: 200px;
    font-size: 16px;
  }
  .costmt {
    margin-left: 20px;
    display: block;
    width: 200px;
    font-size: 16px;
  }

  .amt,
  .vat {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .import_third_line {
    display: block;
  }
  .import_fourth_line {
    display: block;
  }
  .import_fifth_line {
    display: block;
  }
  .amt_usd {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 200px;
    font-size: 16px;
  }
  .exc_rate {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 200px;
    font-size: 16px;
  }
  .amt_ksh {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .narr {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .text {
    width: 350px;
    height: 80px;
  }
  .sec__iport__changes,
  .other-charge,
  .other_1,
  .cost_exc {
    display: block;
    font-size: 16px;
  }
  .input_cost_2 {
    margin-top: 10px;
    margin-left: 1px;
  }
  .other_charges1,
  .cost_exc {
    width: 100%;
    height: auto;
  }
  .cost_2 {
    margin: 10px;
    display: block;
  }
  .cost_vat {
    margin: 10px;
    gap: 120px;
  }
  .total_cost_exc,
  .total_cost_exc_1,
  .total_cost_exc_2,
  .total_cost_exc_3 {
    margin: 10px;
    flex-wrap: wrap;
  }
  .total_cost_exc > p {
    flex-basis: 100%;
  }
  .total_cost_exc > input {
    width: 180px;
    display: block;
  }
  .total_cost_exc_1 > p {
    flex-basis: 100%;
  }
  .total_cost_exc_1 > input {
    width: 180px;
    display: block;
  }
  .total_cost_exc_2 > p {
    flex-basis: 100%;
  }
  .total_cost_exc_2 > input {
    width: 180px;
    display: block;
  }
  .total_cost_exc_3 > p {
    flex-basis: 100%;
  }
  .total_cost_exc_3 > input {
    width: 180px;
    display: block;
  }
  .derived {
    font-size: 18px;
  }
  .mt {
    margin-left: 10px;
  }
  .other_1 {
    padding: 0px;
  }
  .header_import {
    display: block;
  }
  .Save_import {
    margin: 3px 20px 0px 10px;
  }
  .Import_first_line p {
    margin: 5px 0px;
  }
  .select_code,
  .select_name,
  .pro_select_1,
  .pro_select {
    margin: 10px 0px;
  }
}

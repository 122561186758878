.section__Item {
  margin: 10px;
  font-size: 14px;
}
.button__r__f {
  background-color: #002d62;
  padding: 8px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin: 3px;
}
.select_product_type {
  display: flex;

  gap: 10px;
  margin-left: 10px;
  align-items: center;
}
.select_item_name {
  display: flex;

  gap: 10px;
  align-items: center;
}
.item_code {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 70px;
}

.supplier_code {
  display: flex;
  gap: 10px;
  align-items: center;
}

.supplier_name {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 10px;
}

.item_name {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-right: 30px;
}
.category {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 75px;
}

.mobile_no {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 22px;
}

.sub-category {
  display: flex;

  gap: 10px;
  align-items: center;
}
.classification {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 50px;
}
.select_product_type {
  display: flex;

  gap: 10px;
  align-items: center;
}
.select_size {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 65px;
}

.supplier_city {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 237px;
}

.description {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-left: 30px;
}
.standard_cost {
  display: flex;

  gap: 10px;
  align-items: center;
  margin-right: 130px;
}

.supplier_country {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 24px;
}

.item_code_table {
  display: flex;

  justify-content: space-between;
}
.item_second_line_supplier {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 5px;
}
.item_third_line {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}
.item_fourth_line {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}
.item_fifth_line {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}
.section__div {
  padding: 10px;
  border-radius: 5px;
  background-color: aliceblue;
}

.item__code__select2 {
  width: 400px;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
  margin: 3px;
  margin-right: 50px;
}
.item__code__select3 {
  width: 200px;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
  margin: 3px;
}
.item__code__input1 {
  width: 200px;
  border: 1px solid;

  height: 30px;
  margin: 3px;
}
.item__code__input2 {
  width: 300px;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
  margin: 3px;
}
.stock__list {
  color: red;
  padding: 0px 10px;
}
.text__area__item {
  margin-top: 5px;
  max-height: 80px;
  max-width: 800px;
  height: 80px;
  width: 800px;
  padding: 10px;
  margin-left: 8px;
}
.select__margin__master {
  margin: 8px 0px;
  margin-right: 50px;
}
.section__div input {
  margin: 5px 0px;
}

/* Route Master */
.item_code_route {
  display: flex;
  gap: 10px;
  align-items: center;
}

.category_route {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header_route {
  display: flex;
  justify-content: flex-end;
  margin-top: 1px;
}

.header_import {
  display: flex;
  justify-content: space-between;
  /* margin-top: 10px; */
}
.import {
  color: brown;
  margin-left: 20px;
  font-size: 20px;
}
.Save_import {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 16px;
  margin-right: 10px;
  height: 30px;
  border-radius: 5px;
  /* margin: 20px 0px; */
  cursor: pointer;
  width: 70px;
  padding: 5px;
}
.view__detail__button {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 16px;
  margin-right: 10px;
  height: 30px;
  border-radius: 5px;
  /* margin: 20px 0px; */
  cursor: pointer;
  width: 150px;
  padding: 5px;
}
.Import_first_line {
  background-color: aliceblue;
  height: 260px;
  margin-top: 5px;
  font-size: 14px;
}
.import_second_line {
  display: flex;
  margin-top: 25px;
  font-size: 14px;
}
.cost_sheet_no_1 {
  display: flex;
  gap: 15px;
  margin-left: 10px;

  font-size: 14px;
  align-items: center;
  width: 270px;
}
.item_1 {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  width: 540px;
}
.qty_import {
  display: flex;
  gap: 10px;
  margin-right: 5px;
  font-size: 14px;
  align-items: center;
}
.import_third_line {
  display: flex;
  margin-top: 15px;
  font-size: 14px;
}
.import_fourth_line {
  display: flex;
  margin-top: 15px;
  font-size: 14px;
}
.import_fifth_line {
  display: flex;

  font-size: 14px;
  justify-content: space-between;
}

.vat {
  display: flex;
  gap: 3px;

  font-size: 14px;
  align-items: center;
}
.amt {
  display: flex;
  gap: 3px;

  font-size: 14px;
  align-items: center;
  width: 250px;
}
.costmt {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  width: 185px;
}
.cost_item {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  margin-left: 40px;
  width: 420px;
}

.supplier_name {
  display: flex;
  gap: 5px;
  font-size: 14px;
  align-items: center;
  margin-left: 20px;
  width: 400px;
}

.select_name_1 {
  width: 300px;
}
.amt_usd {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  margin-left: 24px;
  width: 340px;
}
.exc_rate {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  width: 285px;
}
.amt_ksh {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
}
.narr {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  margin-left: 43px;
}
.import_btn {
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
  margin: 0px 10px;
}
.checkbos__import__Costing {
  height: 20px;
  width: 20px;
  margin: 5px;
}
.text {
  max-width: 1000px;
  max-height: 100px;
  width: 900px;
  height: 50px;
  margin-top: 10px;
}
.other_charges1 {
  width: 50%;
  background-color: aliceblue;
  height: 500px;
}
.cost_exc {
  width: 50%;
  background-color: aliceblue;
  height: 500px;
}
.cost_vat {
  display: flex;
  color: brown;
  gap: 200px;
  margin-left: 70px;
}
.total_cost_exc {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  margin-left: 30px;
  font-size: 14px;
  align-items: center;
}
.total_cost_input {
  height: 30px;
  width: 200px;
}
.mt {
  margin-left: 80px;
}
.total_cost_exc_1 {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  margin-left: 18px;
  font-size: 14px;
  align-items: center;
}
.total_cost_exc_2 {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  margin-left: 16px;
  font-size: 14px;
  align-items: center;
}
.total_cost_exc_3 {
  margin-top: 10px;
  display: flex;
  gap: 10px;

  font-size: 14px;
  align-items: center;
}
.derived {
  color: brown;
  justify-content: center;
  display: flex;
  font-size: 24px;
  margin-top: 20px;
}
.other-charge {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.pro_select_1 {
  width: 330px;
}
.cost_input {
  height: 30px;
  width: 150px;
  margin-top: 5px;
}
.other_1 {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}
.cost_2 {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  justify-content: end;
  margin-right: 50px;
  margin-top: 20px;
}
.input_cost_2 {
  height: 30px;
  width: 150px;
}

.input_cost_costmt {
  height: 30px;
  width: 100px;
}

.sec__iport__changes {
  display: flex;
}
.input_size_bag5 {
  height: 30px;
  width: 100px;
}
.input_size_bag6 {
  height: 30px;
  width: 100px;
}
.hist_prod1 {
  display: flex;
  align-items: center;
  margin-top: 2px;

  gap: 5px;
}
@media (max-width: 600px) {
  .import_second_line {
    display: block;
  }
  .hist_prod1 {
    display: block;
    margin-left: 10px;
    margin-top: 20px;
  }
  .input_size_bag5 {
    height: 30px;
    width: 150px;
    margin-top: 12px;
    margin-left: 1px;
  }
  .input_size_bag6 {
    height: 30px;
    width: 150px;
    margin-top: 13px;
    margin-left: 1px;
  }
  .cost_sheet_no_1 {
    display: block;
    margin-left: 20px;
    width: 250px;
    font-size: 16px;
  }
  .item_1 {
    display: block;
    margin: 10px;
    width: 300px;
    margin-left: 20px;
    font-size: 16px;
  }
  .Import_first_line {
    height: auto;
  }
  .qty_import {
    display: block;
    margin-top: 20px;
    margin-left: 20px;
    font-size: 16px;
  }

  .cost_item {
    display: block;
    margin-left: 20px;
    width: 200px;
    font-size: 16px;
  }
  .costmt {
    margin-left: 20px;
    display: block;
    width: 200px;
    font-size: 16px;
  }

  .amt,
  .vat {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .import_third_line {
    display: block;
  }
  .import_fourth_line {
    display: block;
  }
  .import_fifth_line {
    display: block;
  }
  .amt_usd {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 200px;
    font-size: 16px;
  }
  .exc_rate {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 200px;
    font-size: 16px;
  }
  .amt_ksh {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .narr {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .text {
    width: 350px;
    height: 80px;
  }
  .sec__iport__changes,
  .other-charge,
  .other_1,
  .cost_exc {
    display: block;
    font-size: 16px;
  }
  .input_cost_2 {
    margin-top: 10px;
    margin-left: 1px;
  }
  .other_charges1,
  .cost_exc {
    width: 100%;
    height: auto;
  }
  .cost_2 {
    margin: 10px;
    display: block;
  }
  .cost_vat {
    margin: 10px;
    gap: 120px;
  }
  .total_cost_exc,
  .total_cost_exc_1,
  .total_cost_exc_2,
  .total_cost_exc_3 {
    margin: 10px;
    flex-wrap: wrap;
  }
  .total_cost_exc > p {
    flex-basis: 100%;
  }
  .total_cost_exc > input {
    width: 180px;
    display: block;
  }
  .total_cost_exc_1 > p {
    flex-basis: 100%;
  }
  .total_cost_exc_1 > input {
    width: 180px;
    display: block;
  }
  .total_cost_exc_2 > p {
    flex-basis: 100%;
  }
  .total_cost_exc_2 > input {
    width: 180px;
    display: block;
  }
  .total_cost_exc_3 > p {
    flex-basis: 100%;
  }
  .total_cost_exc_3 > input {
    width: 180px;
    display: block;
  }
  .derived {
    font-size: 18px;
  }
  .mt {
    margin-left: 10px;
  }
  .other_1 {
    padding: 0px;
  }
  .header_import {
    display: block;
  }
  .Save_import {
    margin: 3px 20px 0px 10px;
  }
  .Import_first_line p {
    margin: 5px 0px;
  }
  .select_code,
  .select_name,
  .pro_select_1,
  .pro_select {
    margin: 10px 0px;
  }
}

.select_supplier {
  height: 30px;
  width: 300px;
}

.select_supplier_small {
  height: 30px;
  width: 150px;
}

.select_supplier_medium {
  height: 30px;
  width: 200px;
}
@media (max-width: 600px) {
  .item_code_table {
    display: block;
  }
  .item_second_line {
    display: block;
  }
  .item_third_line {
    display: block;
  }
  .item_fourth_line {
    display: block;
  }
  .item_fifth_line {
    display: block;
  }
  .select_product_type {
    display: block;

    margin-left: 20px;
  }
  .select_item_name {
    display: block;

    margin-left: 20px;
  }
  .item_code {
    display: block;

    margin-left: 20px;
  }
  .item_name {
    display: block;

    margin-left: 20px;
  }
  .category {
    display: block;

    margin-left: 20px;
  }
  .sub-category {
    display: block;

    margin-left: 20px;
  }
  .classification {
    display: block;

    margin-left: 20px;
  }
  .select_product_type {
    display: block;

    margin-left: 20px;
  }
  .select_size {
    display: block;

    margin-left: 20px;
  }
  .standard_cost {
    display: block;

    margin-left: 20px;
  }
  .description {
    display: block;

    margin-left: 20px;
  }
  .text__area__item {
    width: 300px;
  }
}

.finished_product {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 682px;
}
.cost_sheet_no {
  display: flex;
  gap: 15px;
  margin-left: 20px;

  font-size: 14px;
  margin: 0px 10px;
  align-items: center;
}
.size_of_Bag_transp {
  display: flex;
  align-items: center;
  gap: 10px;
}
.first_line1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.update_buttons {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
.third_line_transport {
  display: flex;
  margin-top: 12px;
}

.qty {
  display: flex;
  align-items: center;
  gap: 38px;
  margin: 0px 10px;
  width: 227px;
}
.trans_charges {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.qty_bags {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.charges {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.charge_f {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.qty_input {
  height: 30px;
  width: 100px;
}
.qty_input1 {
  height: 30px;
  width: 100px;
}
.cost_prod {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sel__for__product {
  display: flex;
  gap: 10px;
  margin: 10px;
  margin-left: 18px;
  align-items: center;
}
.sel__for__product__2nd {
  display: flex;
  gap: 10px;
  margin: 10px;
  margin-left: 10px;
  align-items: center;
}
.alert {
  padding-left: 283px;
  color: red;
}
.input_cost {
  height: 30px;
  width: 200px;
}
.input_cost1 {
  height: 30px;
  width: 200px;
}
.input_sp {
  height: 30px;
  width: 130px;
}
.input_sp1 {
  height: 30px;
  width: 130px;
}
.input_sp2 {
  height: 30px;
  width: 130px;
}
.markup_perc_transport {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px 10px;
}
.pro_select_1 {
  height: 30px;
  width: 200px;
}
.cost_after_product {
  display: flex;
  align-items: center;
  gap: 10px;
}
.selling_transp {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 177px;
  width: 250px;
}
.discount__css {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 251px;
  width: 250px;
}
.fourth_line {
  display: flex;
  margin-top: 10px;
}
.next_line {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.header_transp {
  font-size: 23px;
  color: brown;
}
.btn {
  display: flex;
  margin-top: -30px;
  text-align: center;
  justify-content: end;
  gap: 20px;
}
.select_from {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 70px;
}
.route_id {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}
.select__Style {
  width: 160px;
}
.select_to {
  display: flex;
  align-items: center;
  gap: 10px;
}
.second_line_1 {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.button {
  display: flex;
  justify-content: end;
  gap: 10px;
}
.Derived_items_1 {
  background-color: aliceblue;
  height: 160px;
  font-size: 14px;
  padding: 10px;
}
.total_Bag_1 {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 10px;
}
.input_size_bag4 {
  height: 30px;
  width: 100px;
}
.raw_date {
  display: flex;
  justify-content: end;
}
@media (max-width: 600px) {
  .cost_sheet_no {
    display: block;
    margin-left: 20px;
    width: 200px;
  }
  .finished_product {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 250px;
  }
  .size_of_Bag_transp {
    display: block;
    margin-left: 20px;
    margin-top: 20px;
  }

  .third_line_transport {
    display: block;
  }
  .qty {
    display: block;
    margin-left: 20px;
    width: 250px;
  }
  .qty_input {
    margin-top: 10px;
    width: 150px;
  }
  .qty_input1 {
    margin-top: 12px;
    margin-left: 4px;
    width: 160px;
  }
  .input_size_bag4 {
    height: 30px;
    width: 150px;
    margin-top: 12px;
  }
  .cost_prod {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 250px;
  }
  .input_cost {
    margin-top: 10px;
  }
  .input_cost1 {
    margin-top: 12px;
  }
  .markup_perc_transport {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
  }
  .fourth_line {
    display: block;
  }
  .selling_transp {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
  }
  .cost_after_product {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
  }
  .input_sp {
    margin-top: 10px;

    width: 150px;
  }
  .input_sp1 {
    margin-top: 13px;
    margin-left: 4px;

    width: 160px;
  }
  .input_sp2 {
    margin-top: 15px;
    margin-left: 4px;

    width: 160px;
  }
  .first_line1 {
    display: block;
  }
  .trans_charges {
    display: block;
    margin-left: 20px;
  }
  .qty_bags {
    display: block;
    margin-left: 20px;
  }
  .charges {
    display: block;
    margin-left: 20px;
  }
  .charge_f {
    display: block;
    margin-left: 20px;
  }
  .second_line_1 {
    display: block;
  }
  .select_from {
    display: block;
    margin-left: 20px;
  }
  .select_to {
    display: block;
    margin-left: 20px;
    margin-top: 20px;
  }
  .route_id {
    display: block;
    margin-left: 20px;
    margin-top: 20px;
  }
  .Derived_items_1 {
    background-color: aliceblue;
    height: 650px;
  }
  .button {
    justify-content: flex-start;
    margin-left: 20px;
    margin-top: 10px;
  }
  .update_buttons {
    margin-top: 10px;
    justify-content: flex-start;
  }
}

/* Navbar.css */
.logedIn__as__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}
.centernav {
  /* opacity: 0.5; */
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-container {
  display: flex;
  width: 98%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #002d62;
  color: white;
  border-radius: 5px;
  /* box-shadow: 0px 0px 10px 0px rgba(24, 11, 211, 0.45) inset; */
  transition: all ease-in-out 1s;
}
.navbar-container:hover {
  display: flex;
  width: 98%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px -5px rgba(24, 11, 211, 0.45) inset;
  outline: #fff 2px solid;
}

.navbar-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.navbar-links {
  list-style: none;
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: large;
}

/* .navbar-links li {
    margin-left: 5rem;
  } */

.navbar-links a {
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}

.navbar-links a:hover {
  color: #fff;
}

.navbar-icon {
  display: none;
}

.dropdown_list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.master-select {
  text-align: right;
  font-size: large;
}
.master-select-opt,
.transaction-select-opt {
  /* border: none; */
  /* border-bottom-style: solid;
    border-bottom-color: rgb(255, 255, 255); */
  cursor: pointer;
}
.master-select-opt:focus,
.transaction-select-opt:focus {
  outline: none;
}
.transaction-select {
  font-weight: 500;
  font-size: large;
}
/* Responsive Styling */
.hover-underline-animation {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ccc;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.button__logout__navbar {
  padding: 5px 5px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  background: white;
  color: black;
}
.custom-list {
  list-style-type: disc; /* You can change this to other values like 'circle' or 'square' */
  /* Additional styling for the list, e.g., padding and margin */
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.hover-dropdown .hover-underline-animation {
  display: inline-block;
}

.hover-dropdown:hover .dropdown_list {
  display: block;
}

.dropdown_list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  background-color: #002d62;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-size: 15px;
}

.dropdown_list li {
  padding: 8px 16px;
  list-style: none;
  cursor: pointer;
  width: 260px;
}

.dropdown_list li:hover {
  background-color: #002d62;
}

.dropdown_list li:first-child {
  border-top: 1px solid #ccc;
  font-size: 15px;
}

.dropdown_list li:last-child {
  border-bottom: 1px solid #ccc;
  font-size: 15px;
}
.navbar-brand {
  display: flex;
  align-items: center;
  color: #fff;
}
.navbar-brand:hover {
  display: flex;
  align-items: center;
  color: #fff;
}

.fabar {
  cursor: pointer;
  display: none;
  font-size: larger;
}

.bar {
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin: 4px 0;
  transition: 0.4s;
}

.brand-text {
  margin-left: 10px;
}

.nav-menu {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.nav-item {
  margin: 0 10px;
  cursor: pointer;
}

.fabar {
  display: block;
  font-size: 24px;
}

.nav-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 65px;

  background-color: rgba(0, 45, 98, 0.8);

  color: white;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-menu.open {
  display: flex;
  z-index: 10;
}

.nav-item {
  margin: 10px 0;
}
.logout__fullscren {
  display: none;
}
.log__out__icon {
  font-size: 16px;
  padding: 2px 10px;
  border-radius: 5px;
  margin-left: 20px;
  background-color: #fff;
  color: #002d62;
}
.ul__list__style__scanner li {
  list-style: none;
  padding-left: 0px;
  line-height: 30px;
}
.ul__list__style__scanner {
  list-style: none;
  padding-left: 0px;
}
.navbar__left_head {
  margin-left: 600px;
}
@media screen and (max-width: 768px) {
  .navbar__left_head {
    margin-left: 0px;
    display: none;
  }
  .logout__fullscren {
    display: block;
  }
  .navbar-links {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    background-color: #002d62;
    padding: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 1s ease-in; /* Updated transition duration */
  }

  .navbar-links.active {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 55px;
    left: 5px;
    border-radius: 5px;
    width: 97%;
    background-color: #002d62;
    padding: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 1s ease-out;
    z-index: 10;
  }

  .navbar-links li {
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .navbar-icon {
    display: block;
    transition: transform 3s ease-in-out;
  }

  .navbar-icon.rotate {
    animation: rotateIcon 1s ease-in-out; /* Apply rotation animation */
  }
  .navbar-links li {
    margin-left: 0rem;
  }

  .dropdown-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .master-select {
    text-align: left;
  }
  .button__logout__navbar {
    display: none;
  }
}

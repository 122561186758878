.Admin {
  align-items: center;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  margin-top: 10px;
  font-family: Mulish, fira-sans-2, sans-serif;
}

.bk {
  margin-left: 50px;
  margin-top: 25px;
  background-color: #002d62;
  color: white;
  font-size: 25px;
  height: 38px;
  width: 138px;
  font-family: Mulish, fira-sans-2, sans-serif;
}

.not_found {
  height: 100vh;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: black;
  font-weight: bold;
}

.btn_1 {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 20px;
}
.td_derived_item_code {
  text-align: right;
  padding-right: 28px;
}
.td_derived_item_qty {
  text-align: right;
  padding-right: 35px;
}
.td_derived_item_rate {
  text-align: right;
  padding-right: 55px;
}
.td_derived_item_kg {
  text-align: right;
  padding-right: 20px;
}
.td_derived_item_perc {
  text-align: right;
  padding-right: 25px;
}
.td_derived_item_total {
  text-align: right;
  padding-right: 29px;
}
.td_cost_per_stand {
  text-align: right;
  padding-right: 20px;
}
.production__for__Input input {
  text-align: right;
  padding-right: 5px;
}
.btn_2 {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 20px;
  padding: 0px 10px;
}
.btn_3 {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 20px;
  padding: 0px 10px;
  margin-top: 1px;
}
.raw_date {
  display: flex;

  text-align: center;
  gap: 10px;
  font-size: 14px;
}
.historical_prod_no {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  padding-bottom: 0px;
  font-size: 16px;
}
.hist_prod {
  display: flex;
  align-items: center;
  margin-top: 2px;

  gap: 5px;
}
.date {
  margin-top: 10px;
}
.input_date {
  margin-top: 12px;
}
.input_date_import {
  margin-top: 0px;
}
.Raw_materials {
  background-color: aliceblue;

  padding: 10px 0px;
  margin-top: 5px;
  font-size: 14px;
}

.New {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 16px;
  margin-left: 5px;
  height: 40px;
  border-radius: 5px;
  /* margin: 20px 0px; */
  cursor: pointer;
  width: auto;
  padding: 5px;
}
.Save_1 {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 20px;
  margin-left: -2px;
  height: 40px;
  border-radius: 5px;
  margin: 20px 0px;
  cursor: pointer;
  width: 80px;
}
.update {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 15px;
  margin-left: -2px;
  height: 30px;
  border-radius: 5px;
  margin: 5px 0px;
  cursor: pointer;
  width: 105px;
}
.Add {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 15px;
  margin-left: -2px;
  height: 30px;
  border-radius: 5px;
  margin: 5px 0px;
  cursor: pointer;
  width: 80px;
}
.Remove {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 15px;
  margin-left: -2px;
  height: 30px;
  border-radius: 5px;
  margin: 5px 0px;
  cursor: pointer;
  width: 80px;
}
.first_line {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
}
.Production_no {
  display: flex;
  gap: 15px;
  margin-left: 10px;
  width: 300px;
  font-size: 14px;
  align-items: center;
}

.headerr {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.pro_select {
  height: 30px;
  width: 120px;
  font-size: 14px;
}
.code {
  display: flex;
  gap: 12px;
  width: 298px;
  align-items: center;
}

.print__body tr:nth-child(even) {
  background-color: #f2f2f2;
}

.select_code {
  height: 30px;
  width: 120px;
}
.name_1 {
  display: flex;
  gap: 15px;
  width: 500px;
  font-size: 14px;
  align-items: center;
}
.select_name {
  height: 30px;
  width: 300px;
}
.size_of_Bag {
  display: flex;
  margin-left: 10px;
  gap: 37px;
  text-align: center;
  font-size: 14px;
  align-items: center;
  width: 290px;
}
.input_size_bag {
  height: 30px;
  width: 100px;
}
.input_size_bag1 {
  height: 30px;
  width: 100px;
}
.input_size_bag2 {
  height: 30px;
  width: 100px;
}
.second_line {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
}
.qty_1 {
  display: flex;
  gap: 14px;

  margin-left: 10px;
  width: 475px;
  font-size: 14px;
  align-items: center;
}
.input_qty_1 {
  height: 30px;
  width: 120px;
}
.input_qty_2 {
  height: 30px;
  width: 120px;
}
.input_qty_3 {
  height: 30px;
  width: 120px;
}
.cost_of_90 {
  display: flex;
  gap: 14px;
  text-align: center;

  font-size: 14px;
  align-items: center;
}
.input_cost_of_90 {
  height: 30px;
  width: 120px;
}
.perc {
  display: flex;
  gap: 18px;
  align-items: center;
  margin-left: 10px;
  width: 300px;
  font-size: 14px;
}
.input_perc {
  height: 30px;
  width: 120px;
}
.cost_after_perc {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
}
.input_cost_after_perc {
  height: 30px;
  width: 120px;
  font-size: 14px;
}
.size_of_bag1 {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  margin-top: 15px;
}
.input_size_of_bag {
  height: 30px;
  width: 120px;
}
.third_line {
  display: flex;
  margin-top: 6px;
  font-size: 14px;
}
.third_line_der {
  display: flex;
  margin-top: 6px;
  font-size: 14px;
  justify-content: flex-end;
}
.total_cost {
  margin-top: 5px;
  gap: 5px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;
}
.total_size {
  display: flex;
  margin-right: 100px;
  text-align: center;
  margin-top: 10px;
  gap: 5px;
  font-size: 14px;
}
.total_Bag {
  display: flex;
  text-align: center;
  margin-top: 10px;
  gap: 5px;
  font-size: 14px;
  align-items: center;
}
.input_total_Bag {
  height: 30px;
  width: 200px;
  margin-right: 15px;
  font-size: 14px;
}
.input_total_size {
  height: 30px;
  width: 80px;
}
.Derived_items {
  background-color: aliceblue;
  height: 105px;
  font-size: 14px;
}
.derived_code {
  display: flex;
  margin-left: 10px;
  gap: 5px;
  margin-top: 15px;
  align-items: center;
  width: 270px;
}
.derived_name {
  display: flex;
  margin-top: 15px;
  gap: 10px;
  align-items: center;
  width: 518px;
  margin-left: 10px;
}
.derived_qty {
  display: flex;
  margin-top: 15px;
  gap: 20px;
  align-items: center;
  width: 260px;
}
.size_of_bag {
  display: flex;

  gap: 65px;
  align-items: center;
  width: 408px;
}
.extracted_kg {
  display: flex;

  gap: 5px;
  align-items: center;
  width: 235px;
}
.cost_after {
  display: flex;

  gap: 5px;
  align-items: center;
  width: 271px;
}
.cost_per_bag {
  display: flex;
  gap: 5px;

  align-items: center;
}
.Extract {
  display: flex;

  gap: 73px;
  align-items: center;
  margin-left: 10px;
  width: 285px;
}
.other_charges {
  background-color: aliceblue;
  height: 110px;
  padding-top: 10px;
}
.other_per_90kg {
  display: flex;
  margin-left: 10px;
  gap: 5px;
  align-items: center;
  width: 582px;
}
.other_per_90kg_item {
  display: flex;

  gap: 5px;
  align-items: center;
  width: 390px;
}
.No_of_bags {
  display: flex;

  gap: 5px;
  align-items: center;
}
.h1 {
  margin-top: 0px;
}

.Unapp_payment {
  background-color: #002d62;
  height: 50px;
}
.Titles {
  margin-top: 12px;
  color: white;
  font-size: 25px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.nav_1 {
  display: flex;
  gap: 50px;
}
.back_btn {
  display: flex;
  gap: 20px;
  margin-top: 7px;
  margin-right: 10px;
}

.View {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15385;
  height: 35px;
  width: 75px;
  margin: 0;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .historical_prod_no {
    display: block;
  }
  .hist_prod {
    display: block;
    margin-left: 10px;
  }
  .pro_select {
    width: 160px;
  }
  .cost_sheet_no_1 {
    display: block;
  }
  .first_line {
    display: block;
  }
  .Production_no {
    display: block;
    width: 200px;
    font-size: 16px;
  }
  .input_size_bag {
    margin-top: 14px;
    margin-left: 12px;
    width: 150px;
  }
  .name_1 {
    display: block;
    margin-left: 20px;
    margin-top: 20px;
    width: 200px;
    font-size: 16px;
  }
  .code {
    display: block;
    margin-left: 20px;
    margin-top: 20px;
    width: 200px;
    font-size: 16px;
  }
  .prod_no {
    margin-left: 10px;
  }
  .second_line {
    display: block;
  }
  .Raw_materials {
    background-color: aliceblue;
    height: 900px;
    margin-top: 5px;
    font-size: 16px;
  }
  .size_of_Bag {
    display: block;
    margin-left: 20px;

    text-align: start;

    width: 290px;
    font-size: 16px;
  }
  .qty_1 {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 200px;
    font-size: 16px;
  }
  .cost_of_90 {
    display: block;
    text-align: start;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .input_cost_of_90 {
    height: 30px;
    width: 150px;
    margin-top: 12px;
    font-size: 16px;
  }
  .third_line {
    display: block;
  }
  .perc {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 200px;
    font-size: 16px;
  }
  .input_perc {
    height: 30px;
    width: 150px;
    margin-top: 12px;
  }
  .size_of_bag {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    width: 200px;
    font-size: 16px;
  }
  .cost_after_perc {
    display: block;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;
  }
  .btn_2 {
    justify-content: flex-start;
    margin-top: -5px;
    margin-left: 20px;
  }
  .Derived_items {
    background-color: aliceblue;
    height: 680px;
  }
  .input_qty_1 {
    height: 30px;
    width: 150px;
    margin-top: 15px;
  }
  .total_cost {
    display: block;
  }
  .input_qty_2 {
    height: 30px;
    width: 150px;
    margin-top: 15px;
  }
  .input_qty_3 {
    height: 30px;
    width: 150px;
    margin-top: 16px;
  }
  .derived_name {
    display: block;
    margin-left: 20px;
    width: 250px;
    font-size: 16px;
  }
  .derived_code {
    display: block;
    margin-left: 20px;
    width: 200px;
    font-size: 16px;
  }
  .derived_qty {
    display: block;
    margin-left: 20px;
    width: 200px;
    font-size: 16px;
  }
  .input_size_of_bag {
    height: 30px;
    width: 150px;
    margin-top: 12px;
  }
  .size_of_bag1 {
    display: block;
    margin-left: 20px;
    font-size: 16px;
  }
  .Extract {
    display: block;
    margin-left: 20px;
    width: 200px;
    font-size: 16px;
  }
  .cost_after {
    display: block;
    margin-left: 20px;
    width: 200px;
    font-size: 16px;
  }
  .extracted_kg {
    display: block;
    margin-left: 20px;
    font-size: 16px;
  }
  .cost_per_bag {
    display: block;
    margin-left: 20px;
    font-size: 16px;
  }
  .other_charges {
    height: 300px;
  }
  .other_per_90kg {
    display: block;
    margin-left: 20px;
    width: 200px;
    font-size: 16px;
  }
  .other_per_90kg_item {
    display: block;
    margin-left: 20px;
    margin-top: 20px;
    width: 200px;
    font-size: 16px;
  }
  .No_of_bags {
    display: block;
    margin-left: 20px;
    font-size: 16px;
  }
  .btn_3 {
    justify-content: flex-start;
    margin-left: 20px;
  }
  .raw_date {
    display: block;
    text-align: start;
    margin-left: 10px;
    margin-top: 15px;
    font-size: 16px;
  }
  .Save_import {
    margin: 10px 20px 0px 4px;
  }
  .input_size_bag1 {
    margin-top: 15px;

    width: 150px;
  }
  .input_size_bag2 {
    height: 30px;
    width: 150px;
    margin-top: 12px;
  }
  .input_cost_after_perc {
    height: 30px;
    width: 150px;

    margin-top: 12px;
  }
  .input_total_Bag {
    height: 31px;
    margin-left: 11px;
  }
  .td_derived_item_kg {
    text-align: right;
    padding-right: 20px;
  }
  .input_total_size {
    margin-left: 25px;
  }
}

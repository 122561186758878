.for__amin__login__size {
  min-height: 80vh;
}
.company__name__top {
  margin: 20px;
  color: rgb(23, 23, 140);
  font-weight: 700;
  text-shadow: 3px 3px 3px rgb(204, 214, 220);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.company__name__bottom {
  display: none;
  margin: 20px;
  color: rgb(23, 23, 140);
  font-weight: 700;
  text-shadow: 3px 3px 3px rgb(204, 214, 220);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.input {
  margin-top: 10px;
  background: white;
}
.headeruselogin {
  margin: 0px 20px 20px 20px;
  color: rgb(23, 23, 140);
  display: flex;
  justify-content: center;
  text-shadow: 3px 3px 3px rgb(222, 229, 232);
}
.header {
  height: 100vh;
  width: 100%;
  /* background-color: rgb(246, 250, 250); */
  display: flex;
  justify-content: space-around;

  align-items: center;
}
.loginerror {
  color: red;
  display: flex;
  justify-content: center;
  margin: 0;
}
.image__style {
  max-width: 100%;
  height: auto;
}
label {
  font-size: large;
  margin-top: 10px;
  font-weight: 500;
}
.form_test {
  border-left: 2px rgb(120, 128, 209) groove;
  border-top: 2px rgb(102, 80, 191) groove;
  /* border-color: black;
      border-style: groove; */
  height: 400px;
  width: 375px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 10px 10px 7px rgb(230, 233, 237);
  position: relative;
  animation-name: example2;
  animation-duration: 2s;
}

@keyframes example2 {
  0% {
    right: -150%;
    top: 0px;
  }
  100% {
    right: 0%;
    top: 0px;
  }
}

.form_group {
  width: 300px;
}
.loginpagebtn {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.submitbtn {
  color: aliceblue;
}
.pic__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  animation-name: example;
  animation-duration: 2s;
}

@keyframes example {
  0% {
    left: -100%;
    top: 0px;
  }
  100% {
    left: 0%;
    top: 0px;
  }
}
.submitbutton1 {
  background: rgb(51, 49, 167);
  border-radius: 10px;
  height: 45px;
  width: 200px;
  border: none;
  color: aliceblue;
  transition: 1.2s;
}
.linkbtnremove {
  text-decoration: none;
  color: white;
}
.linkbtnremove:hover {
  color: white;
}
.submitbutton1:hover {
  background: rgb(32, 31, 105);
  transform: scale(1.1);
}
.submitbutton2 {
  background: rgb(50, 49, 96);
  border-radius: 10px;
  height: 45px;
  width: 120px;
  border: none;
  color: aliceblue;
  transition: 1.12s;
}
.submitbutton2:hover {
  background: rgb(28, 27, 56);
  transform: scale(1.1);
}
/* button {
      margin-top: 20px;
    } */
.forgotpass {
  font-size: 14px;
}
.footer__login__page {
  margin-top: 100px;
}

span.skew-shake-x {
  animation: skew-x-shake 1.2s infinite;
  color: red;
  font-weight: 400;
}
.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
}

.checkbox__show__password {
  display: flex;
  justify-content: end;
  align-items: center;
}

.show_p_para {
  margin-top: 0;
  margin-bottom: 0rem;
  margin-right: 10px;
}

@keyframes skew-x-shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
.form_control {
  width: 100%;
  padding: 8px 20px;
  margin: 8px 0;
  border-width: 1px;
  border-style: groove;
  border-radius: 5px;
  background-color: white;
}
.checkbox {
  margin-bottom: 5px;
}
.aside__forgot__and__Show {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1101px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    align-items: center;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .company__name__top {
    display: none;
  }
  .company__name__bottom {
    display: block;
  }
  .form_test {
    height: 480px;
    width: 93%;
  }
  .loginpagebtn {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    margin: 10px;
  }
  .header {
    height: 110vh;
  }
}

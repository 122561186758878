/* whole input fields css */
.style {
  padding: 10px;
  /* line-height: 60px; */
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 7px;
}
/* first row css */
.item {
  display: flex;
  font-family: Mulish, fira-sans-2, sans-serif;
  gap: 114px;
  margin: 0px 0px 8px 0px;
}
.sixth_line {
  display: flex;
}

.item > div {
  display: flex;
}

/* fisrt column css(item code,from,markup,cost before transport */
.co1-1 {
  width: 410px;
}
.col_gap {
  width: 460px;
}

/* item code input feild css */
.select_1 {
  height: 10px;
  width: 150px;
  margin-left: 10px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-size: 14;
}
/* item name label and input feild css */
.name {
  width: 79px;
}

.select_2 {
  height: 20px;
  width: 500px;
  margin-left: 10px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
}

/* second row css */
.id {
  display: flex;
  font-family: Mulish, fira-sans-2, sans-serif;
  gap: 253px;
  margin: 0px 0px 8px 0px;
}

.id > div {
  display: flex;
}
/* from input feild css */
.select_3 {
  height: 20px;
  width: 110px;
  margin-left: 10px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
}
/* second column css */
.col-22 {
  width: 207px;
}
.co1-2 {
  width: 320px;
}
.col-4 {
  width: 400px;
}
.col-5 {
  display: flex;
  width: 405px;
}
.col-6 {
  width: 460px;
}
/* .co1-2 {
  width: 1000px;
} */
/* .co1-2 {
  width: 1000px;
} */

/* To input field css */
.select_4 {
  height: 20px;
  width: 134px;
  margin-left: 10px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
}
/* Route id label and input css */
.Route_id {
  gap: 10px;
}
.select_5 {
  height: 20px;
  width: 80px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
}
/* third row css */
.third_line {
  display: flex;
  font-family: Mulish, fira-sans-2, sans-serif;
}

/* markup price label and input css */
.Markup {
  display: flex;
  font-family: Mulish, fira-sans-2, sans-serif;
}
.transport_cost {
  display: flex;
}

.select_7 {
  border: 1px solid;
  width: 150px;
  height: 30px;
  border-radius: 5px;

  margin-left: 13px;

  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
}

/* selling price label and input field css */
.sell_price {
  display: flex;
  width: 480px;
}
.sell_price > .sell {
  width: 300px;
}
.sell__two {
  width: 300px;
}
.sell {
  /* width: px; */
  font-family: Mulish, fira-sans-2, sans-serif;
}
.sell_last {
  display: flex;
}
.sell_last > div:first-child {
  width: 300px;
}

.select_8 {
  height: 20px;
  width: 100px;
  border: 0;
  margin-left: 13px;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
  color: red;
  pointer-events: none;
}

.sixth1_line {
  display: flex;
  gap: 65px;
  margin: 10px 0px;
  width: 52%;
}
.margin__for__last {
  margin: 10px 0px;
}
/* quantity label and input field css */
/* .quantity {
  display: flex;
} */
.col_gap_1 {
  width: 410px;
}

.select_6 {
  height: 29px;
  width: 70px;
  margin-left: 19px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
}

/* cost transport row css */
.cost {
  display: flex;
  /* padding-bottom: 20px; */
  height: 30px;
}

/* cost before transport label*/
.before {
  display: flex;
  gap: 20px;
}

/* cost after transport css */
.cost_after {
  display: flex;
}

.select_9 {
  height: 20px;
  width: 100px;
  border: 0;
  margin-left: 13px;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
  color: red;
  pointer-events: none;
}
/* cost based on quantity css */
.cost_quantity {
  display: flex;
  justify-content: flex-start;
  /* gap: 90px; */
  /* position: relative; */
  margin: 0px 0px 8px 0px;
}

.before_quan {
  display: flex;
}

.select_11 {
  height: 20px;
  width: 100px;
  border: 0;
  margin-left: 13px;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
  color: red;
  pointer-events: none;
}

.text_area {
  /* width: 89px;
  height: 11px; */

  max-width: 40px;
  text-align: left;

  color: red;
  font-weight: 600;
  outline: none;
  border: none;
  /* border-bottom: 1px solid; */
  pointer-events: none;
  /* border: none; */

  overflow-x: scroll;
  /* font-weight: 600; */
  font-size: 14px;
}

.after_quantity {
  display: flex;
}

.select_12 {
  height: 20px;
  width: 100px;
  border: 0;
  margin-left: 13px;
  border-bottom: 1px solid #ccc;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-weight: 600;
  color: red;
  pointer-events: none;
}
/* view button css */
.View {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 20px;

  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  padding: 10px 20px;
  width: 160px;
}

/* table css */
.main__sec__Align {
  background: #f0f8ff;
  /* border: 1px solid; */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 10px 0px;
  padding: 5px 10px;
}
.section__color {
  display: flex;
  background: #f0f8ff;
  height: 50px;
  align-items: center;
}
.main__section__picker {
  display: flex;
}
.main__section__picker > aside {
  display: flex;
  align-items: center;
  width: 33%;
  gap: 5px;
}

/* .main__section__picker > aside > p {
  width: 90px;
} */

.select__id {
  width: 160px;
}
.select__name {
  width: 300px;
}
.input__text__num {
  height: 35px;
}
.select__Style {
  height: 35px;
  width: 300px;
}
/* 
.Vertical_table {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  border-spacing: 30px;
} */

.table_design {
  border-radius: 3px;
  /* padding: 25px 30px; */

  justify-content: space-between;
  margin-bottom: 25px;
}

/* .td {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  background-color: #002d62;
  color: whitesmoke;
  text-align: center;
  font-family: Mulish, fira-sans-2, sans-serif;
  height: 53px;
  width: 450px;
  justify-content: center;
  align-items: center;
} */

.Trans_det {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
}

.table-header {
  background-color: #002d62;
  color: white;

  text-transform: uppercase;
}
.perMT {
  display: flex;
}
.perMT > div:first-child {
  width: 224px;
}
.transport_cost > div:first-child {
  width: 224px;
}
.after_quantity > div:first-child {
  width: 224px;
}

/* .table_row {
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
} */

.col-1 {
  flex-basis: 10%;
  margin: 0px 10px;
}
.col-2 {
  flex-basis: 40%;
}

/* .col-8 {
  width: 650px;
} */
.col-7 {
  width: 415px;
}
.col-9 {
  width: 460px;
}
.col-10 {
  width: 480px;
  display: flex;
  align-items: center;
}

.col-6 > div:first-child {
  display: flex;
  align-items: center;
  width: 120px;
}
.col-6 > div:last-child {
  display: flex;
  align-items: center;
  width: 500px;
}
.col-11 {
  width: 460px;
}
.col-12 {
  width: 482px;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
.Save {
  background-color: #002d62;
  color: white;
  border: none;
  font-size: 20px;
  margin-left: -2px;
  height: 40px;
  border-radius: 5px;
  margin: 20px 0px;
  cursor: pointer;
  width: 80px;
}

.whole_tabel {
  display: flex;
  justify-content: center;
}
.service_detials {
  /* align-items: center;
  display: flex;
  gap: 30px;
  width: 100%; */
  display: block;
}
.service_detials {
  justify-content: center;
}
.table_1 {
  border-collapse: collapse;
  border-radius: 4px;
  text-transform: uppercase;
  width: 99%;
  margin: 10px 5px;
  font-size: 12px;
}

.table_1,
.table_1 thead {
  outline: 1.5px solid #585757;
}
.table_1,
.table_1 thead {
  outline: 1.5px solid #585757;
}
.table_1 thead {
  background: #002d62;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 98%;
  z-index: 0;
}
.table_1 th {
  min-width: 40%;
  padding: 10px 13px;
  text-align: left;
}
.table_1 th,
.table_1 td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.flex__style__defalt {
  display: flex;
}
.new__first__div {
  display: flex;
  gap: 30px;
}
.new__first__div > aside {
  display: flex;
  align-items: center;
}
.costing__analisis {
  display: flex;
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  .costing__analisis {
    display: block;
  }
  .costing__analisis > div {
    margin-left: 0px !important;
  }
  .item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0px 0px 3px 0px;
  }
  .input__text__num {
    margin-top: 10px;
  }
  .perMT {
    margin-top: 10px;
  }
  .transport_cost {
    margin-top: 10px;
  }
  .third_line {
    display: flex;
    flex-direction: column;
  }
  .select_2 {
    width: auto;
  }
  .perMT {
    display: flex;
  }
  .col-9,
  .col_gap,
  .col_gap_1,
  .col-6,
  .col-22,
  .co1-1,
  .col-7 {
    width: unset;
    margin-top: 10px;
  }
  .after_quantity {
    margin-left: unset;
  }
  .select_11 {
    width: 60px;
  }

  .scroll {
    overflow-x: scroll;
    padding: 20px;
  }
  .id {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0px 0px 3px 0px;
  }
  .cost {
    display: flex;
    flex-direction: column;
    height: unset;
  }
  .cost_quantity {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 3px 0px;
  }
  .visible {
    overflow: scroll;
  }
  .Vertical_table {
    overflow: scroll;
  }
  .whole_tabel {
    overflow: scroll;
  }
  .select_2 {
    width: 250px;
  }
  .select_8 {
    width: 50px;
  }
  .text_area {
    width: 54px;
  }
  .trans_charge {
    width: 200px;
    text-align: right;
  }
  .main__section__picker {
    display: block;
  }
  .main__section__picker > aside:first-child {
    display: block;
  }
  .main__section__picker > aside {
    display: block;
  }

  .main__section__picker > .quantity__input {
    display: block;
  }
  .section__color {
    display: block;
    min-height: 150px;
  }
  .col-10 {
    width: 100%;
  }
  .col-6 > div:last-child {
    width: 100%;
  }
  .Markup {
    margin: 5px 0px;
    display: block;
    font-size: 16px;
  }
  .sell_price > .sell {
    width: 100%;
    margin-top: 15px;
  }
  .sell__two {
    width: 100%;
    margin-top: 15px;
  }
  .sixth_line {
    display: block;
  }
  .col-12 {
    width: 100%;
  }
  .sixth1_line {
    display: block;
    width: 100%;
  }
  .sell_price {
    display: block;
    width: 100%;
  }
  .flex__style__defalt {
    display: block;
  }
  .col-11 {
    width: 100%;
  }
  .new__first__div {
    display: block;
  }
  .new__first__div > aside {
    display: block;
    margin: 5px 0px;
  }
  .before_quan,
  .after_quantity,
  .transport_cost,
  .before,
  .perMT {
    display: block;
  }
  .for__media__margin {
    margin-left: 0px;
  }

  .show {
    display: block;
  }
  .no {
    display: none;
  }
  .select__name {
    margin-top: 10px;
  }
  .select__id {
    margin-top: 10px;
  }
  .to {
    margin-top: 10px;
  }
  .quantity__input {
    margin-top: 10px;
  }
  .select_7 {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.btn_link {
    display: grid;
    justify-content: center;
    padding: 20px;
    gap: 20px;
    text-decoration: none;
    margin-top: 25px;
  }
  .h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
  }
  
  .buttons {
    align-items: center;
    appearance: none;
  
    background-image: radial-gradient(
      100% 100% at 100% 0,
      #1ecaf9 0,
      #002d62 100%
    );
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
  }
  .buttons:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .buttons:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .buttons:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .navigate {
    text-decoration: none;
    color: white;
  }
  
  .logout-btn {
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-size: 20px;
    height: 10px;
    margin-right: 3px;
    opacity: 1;
    padding: 20px 40px;
    text-decoration: none;
    visibility: visible;
    width: 50px;
    margin-top: 7px;
  }
  
  .application {
    text-align: center;
    padding: 2px;
    margin-top: 17px;
  }
  /* .logout_btn {
    padding: 10px 10px 10px 0px;
  } */
  @media only screen and (max-width: 600px) {
    .h2 {
      font-size: 12px;
    }
  }
  